import { Spin } from 'antd';
import { useEffect } from 'react';
import { REDIRECT_TO_KEY } from '../localstorage';
import { mixpanelTrack } from '../utils/mixpanel/mixpanel-track';
import { oktaAuth } from '../utils/okta';
import { UnauthenticatedLayout } from './layouts/unauthenticated-layout';
import { SpinContainer } from './nuspire/spin';

export const LoginCallback = () => {
  useEffect(() => {
    const storedRedirectTo = window.localStorage.getItem(REDIRECT_TO_KEY);
    if (storedRedirectTo) {
      window.localStorage.removeItem(REDIRECT_TO_KEY);
    }
    const redirectTo = storedRedirectTo ?? '/';

    oktaAuth.handleRedirect(redirectTo).then(() => {
      mixpanelTrack('login-success', {
        method: 'username-password',
      });
    });
  }, [oktaAuth]);

  return (
    <UnauthenticatedLayout showAuthActions={false}>
      <SpinContainer>
        <Spin />
      </SpinContainer>
    </UnauthenticatedLayout>
  );
};

import { OktaAuth, OktaAuthOptions } from '@okta/okta-auth-js';

export const originalUri = window.location.origin;

export const oktaConfig: { oidc: OktaAuthOptions } = {
  oidc: {
    clientId: import.meta.env.PROD ? '0oakpdlhoQEplmAsl5d6' : '0oaxumh2xowNpRScQ0h7',
    issuer: import.meta.env.PROD
      ? 'https://mynuspire.okta.com/oauth2/default'
      : 'https://nuspire.oktapreview.com/oauth2/default',
    redirectUri: `${originalUri}/login/callback`,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    postLogoutRedirectUri: `${originalUri}/login`,
  },
};

export const oktaAuth = new OktaAuth(oktaConfig.oidc);
// This is used to set a default location to send the user after a redirect (ex: oktaAuth.signInWithRedirect()).
oktaAuth.setOriginalUri(originalUri);

if (import.meta.env.DEV) {
  // Triggered when any token expires (access, id, or refresh token). Key is the name of the token (i.e., "access").
  // oktaAuth.tokenManager.on('expired', (key, expiredToken) => {
  // console.log('Token with key', key, ' has expired:');
  // console.log(expiredToken);
  // });

  // Triggered when any token has been renewed.
  // oktaAuth.tokenManager.on('renewed', (key, newToken, oldToken) => {
  // console.log('Token with key', key, 'has been renewed');
  // console.log('Old token:', oldToken);
  // console.log('New token:', newToken);
  // });

  // Triggered when an OAuthError is returned via the API (typically during token renewal). Pretty rare from what I can tell.
  oktaAuth.tokenManager.on('error', (err) => {
    console.log('Okta Token Error:', err);
  });
}

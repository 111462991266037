import { Select as S } from 'antd';
import { ActionInputProps } from '../../action-form-field';
import { useQuery, gql } from '@apollo/client';
import styled from 'styled-components';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useClientContext } from 'components/client-context-provider';
import { TechnologyDashSelectDataQuery } from '../../../../../../../types/graph-codegen/graph-types';

const { Option } = S;

const Select = styled(S)`
  width: 50% !important;
`;

export const TECHNOLOGY_DASH_SELECT_DATA = gql`
  query TechnologyDashSelectData($clientId: String) {
    dashboards(clientId: $clientId) {
      id
      name
    }

    technologies {
      id
      name
    }
  }
`;

const TechnologyDashboardSelect = (props: ActionInputProps) => {
  const { value = [{}], onChange } = props;
  const { clientId } = useClientContext();
  const { data, loading } = useQuery<TechnologyDashSelectDataQuery>(TECHNOLOGY_DASH_SELECT_DATA, { variables: { clientId } });

  const handleChange = (values, index, key) => {
    onChange(
      value?.map((v, i) => {
        if (i === index) {
          return {
            ...v,
            [key]: values,
          };
        }
        return v;
      }),
    );
  };

  return (
    <div>
      {value?.map((v, i) => (
        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '.5rem' }}>
          <div style={{ width: 'calc(100% - 22px)' }}>
            <Select
              value={v.technology}
              onChange={(values) => handleChange(values, i, `technology`)}
              loading={loading}
              placeholder="Technology"
            >
              {data?.technologies?.map((t) => (
                <Option key={t.id} value={t.id}>
                  {t.name}
                </Option>
              ))}
            </Select>
            <Select
              value={v.dashboardId}
              onChange={(values) => handleChange(values, i, `dashboardId`)}
              loading={loading}
              placeholder="Dashboard"
            >
              {data?.dashboards?.map((d) => (
                <Option key={d!.id} value={d!.id}>
                  {d!.name}
                </Option>
              ))}
            </Select>
          </div>
          <div style={{ margin: 'auto 0 auto .5rem' }}>
            <PlusCircleOutlined style={{ fontSize: '22px' }} onClick={() => onChange([...value, {}])} />
          </div>
          {value?.length > 1 && (
            <div style={{ margin: 'auto 0 auto .5rem' }}>
              <MinusCircleOutlined
                style={{ fontSize: '22px' }}
                onClick={() => onChange(value?.filter((_v, index) => index !== i))}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TechnologyDashboardSelect;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { oktaAuth } from 'utils/okta';
import Spin, { SpinContainer } from './nuspire/spin';

export default function SAMLCallback() {
  const navigate = useNavigate();

  const loginSAMLUser = async () => {
    if (oktaAuth.isLoginRedirect()) {
      try {
        await oktaAuth.handleLoginRedirect();
      } catch (e) {
        // log or display error details
      }
    } else if (!(await oktaAuth.isAuthenticated())) {
      // Start the browser based oidc flow, then parse tokens from the redirect callback url
      oktaAuth.signInWithRedirect();
    } else {
      // User is authenticated, send them to the homepage.
      return navigate('/');
    }
  };

  useEffect(() => {
    loginSAMLUser();
  });

  return (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  );
}

import { Dispatch, ReactNode, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, NuButton, NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import Spin, { SpinContainer } from 'components/nuspire/spin';
import { WidgetComponent } from '.';
import { WIDGET_COMPONENTS_MAP } from './widget-types';
import { useClientContext } from 'components/client-context-provider';
import { client } from 'utils/graphql';
import { App, Checkbox, Dropdown, Empty, MenuProps, message, Typography } from 'antd';
import * as NuIcons from 'components/nuspire/nu-icon';
import { useDashboardContext } from 'components/dashboard/dashboard-context';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadBase64ContentAsFile } from '../../../utils/download-base64-content-as-file';
import get from 'lodash.get';
import { WidgetDataQuery } from '../../../types/graph-codegen/graph-types';
import { useParams } from 'react-router-dom';
import { widgetDetailPath } from 'components/reporting-and-analysis/paths';
import { HIDE_REMOVE_WIDGET_WARNING_KEY } from '../../../localstorage';

export const CURRENT_DASHBOARD_ID_SP = 'd';

export interface IWidgetDefinition {
  id: string;
  slug: string;
  type: string;
  name: string;
  description?: string;
  configuration?: any;
  data?: any;
  dataType?: string;
}

const WIDGET_DATA = gql`
  query WidgetData(
    $id: String!
    $clientId: String!
    $variables: JSONObject
    $viewingClientId: String!
    $from: Date
    $to: Date
  ) {
    dashboardWidget(id: $id, clientId: $clientId) {
      id
      widget {
        id
        data(variables: $variables, viewingClientId: $viewingClientId, from: $from, to: $to)
        configuration
        description
        settings
        filters {
          key
          value
          operator
        }
      }
    }
  }
`;

export function WidgetContents(props: {
  clientId: string;
  dataTypeKey?: string;
  component: WidgetComponent<any, any>;
  data?: any;
  configuration?: any;
  settings?: any;
  loading: boolean;
  setSubAction?: Function;
  setFooterContent?: Dispatch<ReactNode>;
  onFetch?: (args: { variables?: any }) => Promise<any>;
  title?: string;
  size?: number;
  isReportWidget?: boolean;
}) {
  const {
    clientId,
    dataTypeKey,
    component: Component,
    data,
    configuration,
    loading,
    setSubAction,
    setFooterContent,
    title,
    size,
    isReportWidget,
    settings,
  } = props;

  if (data) {
    return (
      <Component
        clientId={clientId}
        dataTypeKey={dataTypeKey}
        data={data}
        configuration={configuration}
        setSubAction={setSubAction}
        setFooterContent={setFooterContent}
        onFetch={props.onFetch}
        title={title}
        size={size}
        isReportWidget={isReportWidget}
        settings={settings}
      />
    );
  }

  if (loading) {
    return (
      <SpinContainer>
        <Spin />
      </SpinContainer>
    );
  }

  return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />;
}

const REMOVE_WIDGET = gql`
  mutation RemoveWidgetFromDashboard($dashboardWidgetId: String!) {
    removeWidgetFromDashboard(dashboardWidgetId: $dashboardWidgetId) {
      ok
    }
  }
`;

// defined in the backend widgets and returned by the widgetDefinition.getConfiguration method
type Column = {
  title: string;
  dataIndex: string;
  key: string;
};

export function createCSVBase64(args: { rows: any[]; columns?: Column[] }) {
  const { rows, columns = [] } = args;
  const titles = columns?.map((c) => c.title);

  const escapeCSVField = (value?: string | null) => {
    // return empty field instead of "undefined"
    if (!value || !value?.toString().trim()) return '';
    /**
     * escape double quotes and commas
     *
     * Example: foo, "bar, baz" -> "foo, ""bar, baz"""
     */
    return `"${value.toString().replace(/"/g, '""')}"`;
  };

  const headerRow = titles?.map(escapeCSVField).join(',');
  const dataRows = rows
    .map((item) => {
      // using get here because some dataIndex values reference nested object values
      // ex: snow assets model.display_value
      const values = columns.map((c) => escapeCSVField(get(item, c.dataIndex)));
      return values.join(',');
    })
    .join('\n');

  let csvString = `${dataRows}\n`;

  if (headerRow) {
    csvString = `${headerRow}\n${csvString}`;
  }

  return window.btoa(csvString);
}

/**
 * Widget Menu
 * Should only appear on dashaboards.
 */
export function WidgetMenu(props: {
  configuration?: any;
  dashboardWidgetId?: string;
  data?: any;
  editable?: boolean;
  exportable?: boolean;
  widgetId?: string;
  widgetName: string;

  authorized?: boolean; // whether or not user has the right to edit widget or remove from dashboard.
}) {
  const { clientId } = useClientContext();
  const { dashboardWidgetId, data, configuration, editable, exportable, widgetId, widgetName, authorized } = props;

  const { dashboardId } = useParams<{ dashboardId: string }>();

  const [removeWidget] = useMutation(REMOVE_WIDGET, {
    refetchQueries: ['DashboardDetails'],
  });

  const { modal } = App.useApp();

  const handleRemoveWidget = async () => {
    try {
      const result = await removeWidget({
        variables: {
          dashboardWidgetId,
        },
      });

      const ok = result?.data?.removeWidgetFromDashboard?.ok;

      if (!ok) {
        throw new Error('Failed to remove Widget');
      }

      message.success('Widget has been removed from Dashboard');
    } catch (err: any) {
      console.error('Failed to Remove Widget', err);
      message.error('Failed to remove widget from dashboard.');
    }
  };

  const handleRemove = async () => {
    const hideWarning: boolean = window.localStorage.getItem(HIDE_REMOVE_WIDGET_WARNING_KEY) === 'true';

    if (!hideWarning) {
      let checkboxValue: string;

      modal.confirm({
        title: `Remove Widget From Dashboard`,
        content: (
          <div>
            <Typography.Text>
              Warning: Removing this widget will remove it from all dashboards, inlcuding all shared dashboards.
            </Typography.Text>
            <div style={{ marginTop: '1rem' }}>
              <Checkbox
                onChange={(e) => {
                  checkboxValue = JSON.stringify(e.target.checked);
                }}
              >
                Don't show again
              </Checkbox>
            </div>
          </div>
        ),

        okText: `Remove Widget`,
        onOk: async () => {
          window.localStorage.setItem(HIDE_REMOVE_WIDGET_WARNING_KEY, checkboxValue);
          await handleRemoveWidget();
        },
      });
    } else {
      await handleRemoveWidget();
    }
  };

  const handleExport = () => {
    const csvContent = createCSVBase64({ rows: data.tableData, columns: configuration?.columns });

    downloadBase64ContentAsFile({
      content: csvContent,
      contentType: 'text/plain',
      filename: `${widgetName}-${new Date().toISOString()}.csv`,
    });
  };

  if (!authorized && !exportable) {
    // no menu items.

    return null;
  }

  const menuItems: MenuProps['items'] = [];
  if (exportable) {
    menuItems.push({
      key: 'export',
      icon: <DownloadOutlined />,
      onClick: handleExport,
      label: 'Export',
    });
  }

  if (authorized && widgetId && editable) {
    menuItems.push({
      key: 'edit',
      label: (
        <Link to={widgetDetailPath({ clientId: clientId ?? '', id: widgetId, dashboardId })} mode="plain">
          Edit
        </Link>
      ),
    });
  }

  if (authorized && dashboardWidgetId) {
    menuItems.push({
      key: 'remove',
      onClick: handleRemove,
      label: 'Remove',
    });
  }

  return (
    <Dropdown trigger={['click']} placement="bottomRight" menu={{ items: menuItems }}>
      <NuButton shape="circle" icon={<NuIcons.Ellipsis style={{ fontSize: '24px' }} />} type="text" />
    </Dropdown>
  );
}

export interface WidgetProps {
  widgetDefinition: IWidgetDefinition;
  // pass if widget has been saved and is being rendered on a dashboard.
  // if !widgetDef.data, we can call widget.data({ variables }) to get widget data.
  dashboardWidgetId?: string;
  name?: string;
  onRemove?: () => void;
  canEdit?: boolean;
  authorized?: boolean;
  viewingClientId?: string; // clientId
  handleFetch?: (args: { variables?: any }) => Promise<any>;
  size?: number;
}

function Widget(props: WidgetProps) {
  const [subAction, setSubAction] = useState(null);
  const [footerContent, setFooterContent] = useState<ReactNode>(null);
  // data will vary depending on the client viewing said widget.
  const { clientId } = useClientContext();

  const {
    widgetDefinition,
    widgetDefinition: { type, dataType },
    dashboardWidgetId,
    name,
    canEdit,
    viewingClientId,
    authorized,
    size,
  } = props;

  // Get date range from dashboard context.
  const { fromIso, toIso } = useDashboardContext();

  // Query backend for widget data and configuration
  // Widget makes initial data request
  const { data: dashboardWidgetData, loading } = useQuery<WidgetDataQuery>(WIDGET_DATA, {
    variables: {
      id: dashboardWidgetId,
      clientId,
      viewingClientId,
      from: fromIso,
      to: toIso,
    },
    skip: !dashboardWidgetId,
  });

  const data = dashboardWidgetData?.dashboardWidget?.widget?.data ?? widgetDefinition.data;
  const settings = dashboardWidgetData?.dashboardWidget?.widget?.settings ?? {};
  const canExport = type === 'table' || type === 'technology-source-device-health'; // allow any table-based widget to be exported, or the device list

  // Some widgets such as tables and lists will need to fetch more data as user scrolls.
  const handleFetch = async (args: { variables?: any }) => {
    // widget preview fetches data slightly differently.
    if (props.handleFetch) {
      return props.handleFetch(args);
    }

    const fetchResults = await client.query<WidgetDataQuery>({
      query: WIDGET_DATA,
      variables: {
        id: dashboardWidgetId,
        clientId,
        viewingClientId,
        variables: args.variables,
      },
      fetchPolicy: 'network-only',
    });

    const data = fetchResults?.data?.dashboardWidget?.widget?.data;

    return data;
  };

  const configuration = dashboardWidgetData?.dashboardWidget?.widget?.configuration ?? widgetDefinition.configuration;
  const widgetId = dashboardWidgetData?.dashboardWidget?.widget?.id;
  const title = name ?? widgetDefinition.name;

  // widgets are meant to use generic widget types (ie: table, pie chart, etc...);
  const Component = WIDGET_COMPONENTS_MAP[type];
  if (!Component) {
    console.log(`Could not find matching widget component for type: ${type}`);
    return null;
  }

  if (!clientId) {
    return null;
  }

  return (
    <NuCard fullHeight dataIntercomTarget={`widget-${name}`}>
      <NuCardTitle
        title={title}
        actions={
          <>
            {subAction && subAction}
            <WidgetMenu
              configuration={configuration}
              dashboardWidgetId={dashboardWidgetId}
              data={data}
              editable={canEdit}
              exportable={canExport}
              widgetId={widgetId}
              widgetName={title}
              authorized={authorized}
            />
          </>
        }
      />

      <NuCardContent>
        <WidgetContents
          dataTypeKey={dataType}
          clientId={clientId}
          component={Component}
          data={data}
          configuration={configuration}
          loading={loading}
          setSubAction={setSubAction}
          setFooterContent={setFooterContent}
          onFetch={handleFetch}
          title={title}
          size={size}
          settings={settings}
        />
      </NuCardContent>
      {footerContent && footerContent}
    </NuCard>
  );
}

export default Widget;

import { NuCard, NuCardContent, NuCardTitle } from 'components/nuspire';
import { EntitlementsTable } from 'components/services/entitlements-section';

export function EntitlementsCard(props: { clientId: string }) {
  const { clientId } = props;

  return (
    <NuCard>
      <NuCardTitle title="Your Nuspire Logins" />
      <NuCardContent>
        <EntitlementsTable clientId={clientId} />
      </NuCardContent>
    </NuCard>
  );
}
